import { Component, OnInit, NgZone, ViewChild, Renderer2, 
  ElementRef} from '@angular/core';
  import { Location } from "@angular/common";
import { Router, ActivatedRoute,NavigationStart,NavigationEnd   } from '@angular/router'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import {CartService} from '../../../../Lazy-loaded-Modules/Cart-module/cart-page/cart-page.service';
import { UntypedFormGroup, UntypedFormBuilder,Validators } from '@angular/forms';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { AppService } from 'src/app/app.service';
// import { HeaderService } from '../Header/header.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import {HeaderService} from './header.service';
import {config} from 'src/configs/config';
import {HostListener } from '@angular/core';
import { AlertsService } from "src/app/Components/alerts/alerts.service";
import { CheckoutLocalStorageService as CheckoutLocalStorageServiceNew } from 'src/app/Lazy-loaded-Modules/Checkout-new/checkout-local-storage.service';
import { LoginService } from 'src/app/Lazy-loaded-Modules/Account-Modules/login-new/login-new.service';

@Component({
  selector: 'app-two-header',
  templateUrl: './header-two.component.html'
})
export class HeaderTwoComponent implements OnInit {
  @ViewChild('closeModal') private closeModal: ElementRef;
  searchForm: UntypedFormGroup;
  preList = [];
  totalPrice = 0;
  userLoggedIn: boolean = false;
  isSearch: boolean = false;
  productOrList: boolean;
  sendquery_vimpro: UntypedFormGroup;
  productId: any;
  searchTerm: any;
  allCategories: any;
  logoImage : string;
  public discountValue;
  accelbanner:any;
  configs = config;
  reqData1;
  promotionBanner: any;
  fruits:any='fruits'
  vegetablesres:any='vegetablesres'
  othersres:any='othersres'
  fruitsres:any='fruitsres'
  vegetables:any='vegetables';
  Courses:any='Courses';
  others:any='others'
  pages:any='pages'
  routeurl;
  showAlerts = true;
  existingCartProduts = [];
  @ViewChild('dropdownMenuButton') dropdownMenu: ElementRef;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  scrollFunction() {
    if(this.configs.title=='Vimpro'){
      var mybutton = document.getElementById("vimpro_cate");
    
    if (window.pageYOffset< 0 ) {
      
      mybutton.style.display = "block";
    } 
    if(window.pageYOffset== 0 ){
      mybutton.style.display = "block";

    }
    else {
      mybutton.style.display = "none";
    }
      
    }

    
  }

  resultMessage: any;
  constructor(
    config1: NgbDropdownConfig,
    private location: Location,
    // private authService: AuthTokenService,
    private localService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private cartService: CartService,
    private headerService: HeaderService,
    private alertService: AlertsService,
    private LoginService:LoginService,
    private checkoutLocalStorageService1: CheckoutLocalStorageServiceNew,
    private renderer: Renderer2

  ) {
    if(this.configs.title!='LinenRaj'){
      
      config1.placement = 'bottom-right'
      this.location.subscribe(location => {
        // ...close popup
        this.closeModal.nativeElement.click();
        this.validateForm=false;
      });

    }
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: any) => {
      //console.log(event.url,"event.url123")
      this.route.url=event.url; 
      this.routeurl=event.url;
      console.log(this.route.url,"this.route.url")
     })
   
  }

  ngOnInit(): void {
    //console.log(appService.userName)
    if(this.configs.title=="Accel"){
      this.renderer.listen('document', 'click', (event: Event) => {
        if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
          this.openacceldropdownclose();
          //document.getElementById("dropdownMenuButton").click()
        }
      });

    }
   
    this.routeurl = this.router.routerState.snapshot.url;
    console.log( this.routeurl,this.router,"this.routeurl",(window.location.href))
    // this.cartService.totalCartItems();
    // this.appService.cartProducts.results = this.cartService.getCartProducts();

    
   // console.log("routeurl",currentUrl)
    console.log("userLoggedIn"+this.userLoggedIn);
    this.userLoggedIn = this.appService.loggedin_user;
    this.searchForm = new UntypedFormGroup({
      search_term: this.formBuilder.control('')
    })
    this.sendquery_vimpro = new UntypedFormGroup({
      customer_name: this.formBuilder.control("", [
        Validators.required,
        Validators.maxLength(30),
        Validators.pattern(/^[a-zA-Z\s\W]+$/)
      ]),
      customer_mobile: this.formBuilder.control("", [
        Validators.required,
        Validators.max(9999999999), Validators.min(1000000000)
      ]),
      customer_email: this.formBuilder.control("", [
        Validators.required,
        Validators.pattern(/^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i)
      ]),
      company_name: this.formBuilder.control(""),
      queries: this.formBuilder.control("", [Validators.required,Validators.maxLength(600)])
    });

    this.getAllCategories();

    this.getLogo();
  
    this.getPromotionBanner();

    // if (this.authService.getUserName() != 'undefined' && this.authService.getUserName()) {
    //   //The front-end should change when this is called.
    //   this.appService.userLoggedIn = true;
    //   //this.appService.userName = this.authService.getUserName();
    // }
    // else {
    //   this.appService.userLoggedIn = false;
    //   this.appService.userName = "";
    // }

    //TO CLEAR THE SEARCH TERM FROM OTHER EVENTS
    this.headerService.currentData.subscribe(
      currentData =>{
        if(currentData === 'clearSearchTerm'){
          this.closePrelist();
        }
      }
    )
  }
  openacceldropdown(){
    console.log("1232345")
    document.getElementById("dropdown").classList.toggle("show");
  }
  openacceldropdownclose(){
    console.log("1232345")
    const dropdown = document.getElementById("dropdown");
    dropdown.classList.remove("show")
    //document.getElementById("dropdown").classList.toggle("");
  }

  getPreList(){
    let searchData = this.searchForm.value.search_term;
    if(searchData.length >=2){
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
            "search": searchData
          }
        }
      }
      this.appService.postApiCall(reqData, ENDPOINTS.GET_PRELIST).subscribe(
        resp => {
          this.preList = resp.result.data;
          
        },
        err => {
          if (err) {
            console.log("Unable to get prelist data: ", err.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
        
    }
    
    else{
      this.preList = [];
    }
  }
  get f_vimpro() {
    if(this.configs.title === "Vimpro"){
    return this.sendquery_vimpro.controls;
    }
  }
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  validateForm=false;
  close_vimpro_model()

  {
    this.validateForm=false;
    this.sendquery_vimpro.reset();
    this.closeModal.nativeElement.click();
    
  }
  saveForm_vimpro() {
   
    // console.log(this.sendquery_vimpro.value.customer_email)
    // console.log(this.sendquery_vimpro.value.customer_name)
    // console.log(this.sendquery_vimpro.value.customer_mobile)
    // console.log(this.sendquery_vimpro.value.queries)
    // console.log(String(this.sendquery_vimpro.value.customer_mobile).length)
    var emailpattern=/^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i
    var namepattern=/^[a-zA-Z\s\W]+$/;
    if (this.sendquery_vimpro.value.customer_email === ""||this.sendquery_vimpro.value.customer_email === null||!emailpattern.test(this.sendquery_vimpro.value.customer_email)) {
      this.validateForm = true;
    } else if (this.sendquery_vimpro.value.customer_mobile === ""||this.sendquery_vimpro.value.customer_mobile === null||String(this.sendquery_vimpro.value.customer_mobile).length !=10) {
      this.validateForm = true;
    } else if (!namepattern.test(this.sendquery_vimpro.value.customer_name)||this.sendquery_vimpro.value.customer_name === ""||this.sendquery_vimpro.value.customer_name===null) {
      this.validateForm = true;
    }
    else if (this.sendquery_vimpro.value.queries === ""||this.sendquery_vimpro.value.queries ===null) {
      this.validateForm = true;
    }
    else{
      this.validateForm = false;
      this.alertService.success("Query Submitted Successfully", this.options);
      let requestData = {
        domain_name: ORG_DETAILS.DOMAIN_NAME,
        customer_name:this.sendquery_vimpro.value.customer_name,
        customer_mobile:this.sendquery_vimpro.value.customer_mobile,
        customer_email:this.sendquery_vimpro.value.customer_email,
        company_name:this.sendquery_vimpro.value.company_name,
        queries:this.sendquery_vimpro.value.queries,
      };
      this.closeModal.nativeElement.click();
      this.appService.postApiCall(requestData, ENDPOINTS.SENDVIMPRO_QUERY).subscribe(
        resp => {
          
          this.sendquery_vimpro.reset();
          
        },
        err => {
          if (err) {
            console.log("error in submitting form", err.message)
          }
          else {
            console.log('error in submitting form!')
          }
        })
  
    }
   

    
  }
  submitEnquiryForVimpro(){
    let userId = this.localService.get('UserId');
		 if(userId){
      var customerdata=JSON.parse(this.localService.get('CustomerData'));
      //console.log(customerdata)
      this.sendquery_vimpro.controls['customer_name'].setValue(customerdata.userName!=null?customerdata.userName:'' , {onlySelf: true});
      this.sendquery_vimpro.controls['customer_email'].setValue(customerdata.email!=null?customerdata.email:'' , {onlySelf: true});
      this.sendquery_vimpro.controls['customer_mobile'].setValue(customerdata.mobileNo!=null?customerdata.mobileNo:'' , {onlySelf: true});
		
			 }
        document.getElementById("sendquery-model-vimpro").click();
       
    }
  getPromotionBanner() {
    let respData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        }
      }
    }
    this.appService.postApiCall(respData, ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        if(resp.message!="No records found"){
          this.promotionBanner=resp.result.data[0].eligible_banner_message
        }
      },
      err => {
        if (err) {
          console.log("Unable to get Promotion Banner: Error: ", err.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }


  hideBanner(){
    document.getElementById("banner").style.display="none"
  }

  savePreList(val){
    this.localService.remove("option1")
    this.localService.remove("option2")
    let userId = this.localService.get('UserId');
   if (this.configs.title === 'Carience'||this.configs.title === 'Karupattiraja') {
    if(userId){
      this.sendProductDetails(val);
     }
    else{
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   }
   else{
    if(this.configs.title=="kubendran" || this.configs.title=="AvonSeals" || this.configs.title=="SpecialWires" || this.configs.title=="Vistas" || this.configs.title=="Cle"|| this.configs.title=="BestGifts"){
      document.getElementById("searchnav").style.width = "0%";
      var navbar = document.getElementById("myfruits")
      if(navbar!=undefined && this.configs.title=="BestGifts" ){
        console.log(navbar,"navbar")
        document.getElementById("myfruits").style.width = "0%"

      }


    }
    this.sendProductDetails(val);
  
    
   }
    //document.getElementById("searchnav").style.width = "0";
    
  }

  savePreList1(){
    var searchVal1 = this.searchForm.controls['search_term'].value;
    if(searchVal1){
      let searchData = {
        "name": searchVal1,
        "type": ""
    }
      this.sendProductDetails(searchData);
    }
  }

  logIn(){
    if(this.configs.title === "Karupattiraja")
    {
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
    }
    if(this.configs.title === "Accel"){
      if(this.appService.userLoggedIn){
        this.router.navigate([`/my-account`], { skipLocationChange: false });

      }
      else{
        this.router.navigate([`/login-new`], { skipLocationChange: false });

      }

    }
    else{
      this.router.navigate([`/login-new`], { skipLocationChange: false });

    }
   
   
    //this.router.navigate([`/login-new`], { skipLocationChange: false });

  }
  

  logOut() {
   
    this.appService.userLoggedIn = false;
    this.appService.userName = "";
    console.log( this.appService.userName,"login user")

    this.appService.loggedin_user = false;
    this.userLoggedIn = this.appService.loggedin_user;
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('expireTime');
    this.localService.remove('UserName');
    this.localService.remove('UserId');
    this.localService.remove('customerGroup');
    this.localService.remove('domainName');
    this.localService.remove('rexpireTime');
    this.localService.remove('AiemaUserName');
    this.localService.remove('couponCode');
    this.localService.remove('company_name');
    this.localService.remove('gst_number');
    this.localService.remove('company_address');
    // this.ngOnInit();
    // this.cartService.clearCart();
    //this.loggedIn = false;
    this.router.navigate(["/"]);
    this.checkoutLocalStorageService1.clearDataonLogout();
    this.checkoutLocalStorageService1.removeOrderId();

    if(config.title=='Karupattiraja')
    {
      this.appService.removeSession();
    }

  }
  productDetails(id) {
    
 
      this.router.navigate(["/product-details", id], {
        relativeTo: this.route,
        skipLocationChange: false,
      });
    }
  
    productList(cate){
      this.headerService.updateAdminFormEvent('clearSearchTerm');
      if(config.title==='Accel') {
        this.router.navigate([`/productListing`], {queryParams: { terms:[[],[cate],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
       }
    }
    

  sendProductDetails(searchTerm){
    console.log("searchTerm"+searchTerm)
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm.name
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }
    // MAKE AN API CALL
     this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
      resp => {
        let respData = resp.result.data;
        this.preList = [];
        //DIRECT TO PRODUCT DETAILS IF ID PRESENT
        console.log("respData.length1"+respData.length)
        if(respData.length === 1){
          console.log("respData.length2"+respData.length)
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "Aiema" ||this.configs.title === "Cle"){
              this.router.navigate([`/company-details1/${respData[0].id}`]);
            }
            else{
              console.log("respData.length3"+respData.length)
              this.router.navigate([`/product-details/${respData[0].id}`]);
            }
            
          })
        }
        else if (respData.length > 1){
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "BBold"){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
             else if(this.configs.title === "Beaubelle" ||this.configs.title === "Accel" || this.configs.title === "BombayHardware"||this.configs.title === "Vimpro"||this.configs.title === "SpecialWires" || this.configs.title=="Vistas"){
              if(searchTerm.type=='Brand'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Product Name'){
                this.router.navigate([`/product-details/${searchTerm.id}`]);
              }
             // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                }
                else if(this.configs.title === "Karupattiraja"){
                  let userId = this.localService.get('UserId');
    if(userId){
      if(searchTerm.type=='Brand'){
        this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        }
        else if(searchTerm.type=='Category'){
          this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        }
        else if(searchTerm.type=='Product Name'){
          this.router.navigate([`/product-details/${searchTerm.id}`]);
        }
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      }
    else{
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   
                
                 // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                    }
                else if(this.configs.title === "Kubendran"){
                  if(searchTerm.type=='Brand')
                  {
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[searchTerm.name],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
    
                  }
                  else if(searchTerm.type=='Category')
                  {
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
                  }
                  else
                  {
                    
                    this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
    
                  }
                  
                }
             
            else if(this.configs.title=="Mayan"){
              if(searchTerm.type=="Category")
              this.router.navigate([`/product`], {
                queryParams: {
                  terms: [searchTerm.name, searchTerm.type],
                  type: "searchresults",
                },
                relativeTo: this.route,
                skipLocationChange: false,
              });
              else{
                this.router.navigate([`/product-details/${respData[0].id}`]);
              }

            } 
              
            //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
            else if (this.configs.title=="BombayHardware" || this.configs.title=="AvonSeals" || this.configs.title=="ShubhCards" || this.configs.title=="SpecialWires" || this.configs.title=="Vistas" ){
              console.log("category",)
              if(searchTerm.type=='Category' && this.configs.title!='ShubhCards')
             
              {
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
               

              }
              if(this.configs.title=='ShubhCards' && searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

              }

              else
              {
                console.log("111")
                this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
              }
            }
            else {
              console.log("222")
              if(this.configs.title=="Cle"){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

              } else {
                this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
              }
              // this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

            }
          })
        }
        else{
          this.searchForm.controls['search_term'].setValue(searchTerm.name);

          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "BBold"){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
            else if(this.configs.title === "Mayan" && (searchTerm.type=="Category")){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
            else if(this.configs.title === "Mayan" && (searchTerm.type!=="Category")){
              this.router.navigate([`/product-details/${respData[0].id}`])
            } 
            else if(this.configs.title === "Beaubelle"||this.configs.title === "Vimpro"){
              if(searchTerm.type=='Brand'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });


              }
             // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                }
                else if(this.configs.title === "Accel"){
                  
                      if(searchTerm.type=='Category'){
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
    
                  }
                 // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                    }
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
            else {
              console.log("333")
              this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

            }
          })
        }
      },
      err => {
        if (err) {
          console.log("Error with product details: ", err.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  sendProductDetails1(searchTerm){
    console.log("searchTerm"+searchTerm)
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }
    // MAKE AN API CALL
     this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
      resp => {
        let respData = resp.result.data;
        this.preList = [];
        //DIRECT TO PRODUCT DETAILS IF ID PRESENT
        console.log("respData.length1"+respData.length)
        if(respData.length === 1){
          console.log("respData.length2"+respData.length)
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "Aiema" ||this.configs.title === "Cle"){
              this.router.navigate([`/company-details1/${respData[0].id}`]);
            }
            else{
              console.log("respData.length3"+respData.length)
              this.router.navigate([`/product-details/${respData[0].id}`]);
            }
            
          })
        }
      
        
               
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
           
          })
        }
      
     
  

  getAllCategories() {
    if(this.configs.title==='BombayHardware' || this.configs.title==='AvonSeals' || this.configs.title=="SpecialWires" || this.configs.title=="Vistas" || this.configs.title=="Accel"){
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "sort_order",
            "sortorder": -1
          }
        }
      }
    } 
    else if(this.configs.title==='Vimpro'){
    
        this.reqData1 = {
          "domain_name": ORG_DETAILS.DOMAIN_NAME,
          "user_id": null,
          "extras": {
            "find": {
            },
            "pagination": false,
            "paginationDetails": {
              "limit": 1,
              "pagesize": 25
            },
            "sorting": true,
            "sortingDetails": {
              "sortfield": "sort_order",
              "sortorder": -1
            }
          }
        }
      
    }
    else {
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "name",
            "sortorder": -1
          }
        }
      }
    }
		this.appService.postApiCall(this.reqData1, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
			resp => {
				this.allCategories = resp.result.data;
			},
			err => {
				if (err) {
					console.log(err)
				}
				else {
					console.log('Something bad happened; Please try again!')
				}
			})
	}

  getAllProductList(category){
    this.searchForm.controls['search_term'].setValue(category);
    this.router.navigate([`/productListing`], {queryParams: { terms:[category], type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
  }
  

  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source;
      this.accelbanner = respData.carousel.slides;
    },
    err => {
      if (err) {
        console.log("Unable to obtain logo \n, ERROR:", err.message);
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }
 openNavkub() {
    document.getElementById("myfruits").style.width = "0";
    document.getElementById("myvegetables").style.width = "0";
    document.getElementById("Courses").style.width = "0";
   // document.getElementById("myothers").style.width = "0";
    document.getElementById("mySidenav").style.width = "60%";
  }
  openNavkub1() {
    document.getElementById("myfruits1").style.width = "0";
    document.getElementById("myvegetables1").style.width = "0";
    //document.getElementById("myothers1").style.width = "0";
    document.getElementById("mySidenav1").style.width = "60%";
  }
  opensearchnavkub() {
    document.getElementById("searchnav").style.width = "100%";
    document.getElementById("mySidenav").style.width = "0";

  }
  closesearchnavkub() {
    document.getElementById("searchnav").style.width = "0";
    
  }
  closeNavkub1() {
    document.getElementById("mySidenav").style.width = "0";
    
  }
  closeNavkub11() {
    document.getElementById("mySidenav1").style.width = "0";
    
  }
  openNav() {
    document.getElementById("myfruits").style.width = "0";
    document.getElementById("myvegetables").style.width = "0";
    document.getElementById("myothers").style.width = "0";
    document.getElementById("mySidenav").style.width = "60%";
  }
  openNav1() {
    document.getElementById("myfruits1").style.width = "0";
    document.getElementById("myvegetables1").style.width = "0";
    document.getElementById("myothers1").style.width = "0";
    document.getElementById("mySidenav1").style.width = "60%";
  }
  
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    
  }
  closeNav1() {
    document.getElementById("mySidenav1").style.width = "0";
    
  }

  bestgifts(){
    this.productsList();
    this.closeNav();
  }

  search(){
    this.isSearch = true;
  }

  decreaseItem(index)
  {
    let tempArray = this.appService.cartProducts.results;
    if(this.configs.title==='ShubhCards')
  {

    if(this.appService.cartProducts.results[index].quantity- tempArray[index].product.pricing.raw.decrementvalue < tempArray[index].product.pricing.raw.minimumorderquantity)
    {
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        this.loadExistingCart()
        return
    }
    
    tempArray[index].quantity = (Number(tempArray[index].quantity) - tempArray[index].product.pricing.raw.decrementvalue)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    this.loadExistingCart()
  }
  else
  {

    console.log("App service products: ", this.appService.cartProducts.results)
    console.log("tempArray data: ", tempArray[index])
    if(tempArray[index].product.pricing !== undefined){
      if(this.appService.cartProducts.results[index].quantity === 1 || this.appService.cartProducts.results[index].quantity  < tempArray[index].product.pricing.raw.minimumorderquantity) {
      console.log("- button pressed!")
        this.appService.cartProducts.results.splice(index,1);
        this.appService.totalCartItems = this.cartService.totalCartItems();
        this.calculateTotal();
        this.cartService.updateCartItem(this.appService.cartProducts.results);
        if(this.configs.title==='Deera'){
          let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
          this.localService.set("cart", cartItemsString);
          }
        this.loadExistingCart()
        return
    }
  }
    tempArray[index].quantity = (Number(tempArray[index].quantity) - 1)
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    
    //   let obj =
    //   {
    //       "domain_name": ORG_DETAILS.DOMAIN_NAME,
    //       "user_id": ORG_DETAILS.USER_ID,
    //       "extras": {
    //           "find": {
    //             "product_id": Number(tempArray[index].product_id),
    //             "product_cost": Number(tempArray[index].cost),
    //             "quantity": Number(tempArray[index].quantity)
    //           }
    //       }
    //     }
    
    //   this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
    //     resp => {
    //       if (resp) {
    //         if (resp.success === 1 && resp.status_code === 200) {
    //           console.log("tempArray[index].quantity"+tempArray[index].product.id);
    //           tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
    //           tempArray[index].product.per_quantity_amount = resp.result.data.per_quantity_amount;
    //           tempArray[index].product.discount_amount = resp.result.data.discount_amount;
    //           tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
    //           tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
    //           this.discountValue = Number(
    //             resp.result.data.discount_applied_to_product_value
    //           );
    //           this.cartService.updateCartItem(this.appService.cartProducts.results);
    //           this.appService.cartProducts.results = tempArray;
    //           this.calculateTotal();
    //           this.cartService.updateCartItem(this.appService.cartProducts.results);
    //           if(this.CONFIG.name==='Deera'){
    //             let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    //             this.localService.set("cart", cartItemsString);
    //             }
    //           this.loadExistingCart()	
    //         }
    //         else if (resp.success === 0) {
    //           if (resp.message) {
    //             //this.alertService.error(resp.message, this.options);
    //           }
    //         }
    //         else if (resp.message && resp.status_code !== 200) {
    //           //this.alertService.error(resp.message, this.options);
    
    //         }
    //         else {
    //           //this.alertService.error('Something bad happened. Please try again!', this.options);
    //         }
    //       }
    //     },
    //     err => {
    //       if (err.error.statusCode === 403) {
    //         //this.alertService.error('Session Time Out! Please login Again', this.options)
    
    //       }
    //       else if (err.error.error.message) {
    //         //this.alertService.error(err.error.error.message, this.options)
    //       }
    //       else {
    //         //this.alertService.error('Something bad happened. Please try again!', this.options);
    //       }
    //     }
    //   )}
      // if (ORG_DETAILS.NAME === "Vistas"){
      //   this.loaderService.emitLoading();
      //   let userId = this.localService.get('UserId');
      //   let obj = {
      //     "domain_name": ORG_DETAILS.DOMAIN_NAME,
      //     "user_id": ORG_DETAILS.USER_ID,
      //     "extras": {
      //         "find": {
      //           "product_id": Number(tempArray[index].product_id),
      //           "product_cost": Number(tempArray[index].cost),
      //           "quantity": Number(tempArray[index].quantity),
      //         customer_id: Number(userId)
      //       },
      //     },
      //   };
  
      //   try {
      //     this.appService
      //       .postApiCall(obj, ENDPOINTS.STRUCTURED_PROMOTION)
      //       .subscribe(
      //         (resp) => {
      //           if (resp) {
      //             if (resp.success === 1 && resp.status_code === 200) {
      //               this.appService.cartProducts.results[index]["discount_amount"] =
      //             resp.result.data.discount_summary[0].discount_amount;
         
      //         this.appService.cartProducts.results[index][
      //           "after_dicount_price"
      //         ] = resp.result.data.discount_summary[0].after_dicount_price;
      //             } else if (resp.success === 0) {
      //               if (resp.message) {
      //                 //this.alertService.error(resp.message, this.options);
      //               }
      //             } else if (resp.message && resp.status_code !== 200) {
      //               //this.alertService.error(resp.message, this.options);
      //             } else {
      //               //this.alertService.error('Something bad happened. Please try again!', this.options);
      //             }
      //           }
      //         },
      //         (err) => {
      //           if (err.error.statusCode === 403) {
      //             //this.alertService.error('Session Time Out! Please login Again', this.options)
      //           } else if (err.error.error.message) {
      //             //this.alertService.error(err.error.error.message, this.options)
      //           } else {
      //             //this.alertService.error('Something bad happened. Please try again!', this.options);
      //           }
      //         }
      //       );
      //   } catch (err) {
      //     console.log("ERROR IN ADDING ITEM: ", err);
      //   }
      // }
    } 

    if(this.configs.title!=="Deera"){
      this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    // if(this.CONFIG.name==='Deera' || this.CONFIG.name==='BestGifts'){
    //   let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    //   this.localService.set("cart", cartItemsString);
    //   }
    this.loadExistingCart()
    }
  }

  increaseItem(index)
  {
    this.alertService.clear();

    let tempArray = this.appService.cartProducts.results;

    let cartQty = 0

    tempArray.forEach((item)=>
    {
        cartQty += item.quantity;
    })

    
    if(this.configs.title==='ShubhCards')
    {
        
      tempArray[index].quantity = (Number(tempArray[index].quantity) + tempArray[index].product.pricing.raw.incrementvalue)
    }
    else{
      if(Number(this.localService.get('max_cart_qty')) <= cartQty)
    {
      this.showAlerts = true;
      window.scrollTo(0,0);
      // this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      if(this.configs.title==='BombayHardware')
      {
        //this.alertService.error("Maximum allowed quantity is 1 only",this.options)
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      } else if(this.configs.title==='Deera'){
        // tempArray[index].quantity = 0;
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      else {
        this.alertService.error("Maximum allowed quantity is " +this.localService.get('max_cart_qty') +" only",this.options)
      }
      return
    }
    if(tempArray[index].product.pricing.raw.incrementvalue>0)
    {
      tempArray[index].quantity = (Number(tempArray[index].quantity) + Number(tempArray[index].product.pricing.raw.incrementvalue));
    } else {
      tempArray[index].quantity = (Number(tempArray[index].quantity) + 1);
    }
      // tempArray[index].quantity = (Number(tempArray[index].quantity) + 1)
    }
    
  if(this.configs.title==='Deera'){
    let obj =
    {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": ORG_DETAILS.USER_ID,
        "extras": {
            "find": {
              "product_id": Number(tempArray[index].product_id),
              "product_cost": Number(tempArray[index].cost),
              "quantity": Number(tempArray[index].quantity)
            }
        }
      }
  
    this.appService.postApiCall(obj,ENDPOINTS.QUANTITY_PROMOTION).subscribe(
      resp => {
        if (resp) {
          if (resp.success === 1 && resp.status_code === 200) {
            console.log("tempArray[index].quantity"+tempArray[index].product.id);
            tempArray[index].product.discount_value = resp.result.data.discount_applied_to_product_value;
            tempArray[index].product.per_quantity_amount = resp.result.data.per_quantity_amount;
            tempArray[index].product.discount_amount = resp.result.data.discount_amount;
            tempArray[index].product.fixed_amount = resp.result.data.fixed_amount;
            tempArray[index].product.discount_percentage = resp.result.data.discount_percentage;
            this.discountValue = Number(
              resp.result.data.discount_applied_to_product_value
            );
            if(resp.result.data.discount_percentage>0)	
            {	
              this.appService.cartProducts.results[index]['fixed_amount'] = resp.result.data.discount_amount;	
            }	
            else{	
              this.appService.cartProducts.results[index]['fixed_amount'] =  resp.result.data.fixed_amount;	
            }	
            this.cartService.updateCartItem(this.appService.cartProducts.results);	
            tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
            this.appService.cartProducts.results = tempArray;
            this.cartService.updateCartItem(this.appService.cartProducts.results);
            this.calculateTotal();
            if(this.configs.title==='Deera'){
              let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
              this.localService.set("cart", cartItemsString);
              }
            this.loadExistingCart()
          }
          else if (resp.success === 0) {
            if (resp.message) {
              //this.alertService.error(resp.message, this.options);
            }
          }
          else if (resp.message && resp.status_code !== 200) {
            //this.alertService.error(resp.message, this.options);
  
          }
          else {
            //this.alertService.error('Something bad happened. Please try again!', this.options);
          }
        }
      },
      err => {
        if (err.error.statusCode === 403) {
          //this.alertService.error('Session Time Out! Please login Again', this.options)
  
        }
        else if (err.error.error.message) {
          //this.alertService.error(err.error.error.message, this.options)
        }
        else {
          //this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
  }
  
   if(this.configs.title!=="Deera" ){
    tempArray[index].sub_total = (Number(tempArray[index].quantity) * Number(tempArray[index].cost))
    this.appService.cartProducts.results = tempArray;
    this.calculateTotal();
    this.cartService.updateCartItem(this.appService.cartProducts.results);
    // if(this.CONFIG.name==='Deera' || this.CONFIG.name==='BestGifts'){
    //   let cartItemsString = JSON.stringify(this.appService.cartProducts.results);
    //   this.localService.set("cart", cartItemsString);
    //   }
    this.loadExistingCart()
   } 
  }

  navigateToList(category) {
    //this.searchForm.controls['search_term'].setValue(category.name);
  if(this.configs.title === "ShubhCards")
  {
    
    // this.localService.set('is_category_filter','true')
    // this.localService.set('category_filter_name',category.name)

  } 
  if(this.configs.title === "Deera" || this.configs.title === "BestGifts" || this.configs.title === "Beaubelle"|| this.configs.title === "Kubendran")
  {
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }
  if (this.configs.title === 'BombayHardware' || this.configs.title==='AvonSeals'|| this.configs.title==='Vimpro' || this.configs.title=="SpecialWires" || this.configs.title=="Vistas") {
    this.localService.set('allProducts','notall')

    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   console.log("userId");
   let userId = this.localService.get('UserId');
   if (this.configs.title === 'Carience'||this.configs.title === 'Karupattiraja') {
    if(userId){
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    }
    else{
      this.LoginService.updateAdminFormEvent({name: "ask_login"});
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   }
   if (this.configs.title === 'Aiema' || this.configs.title === 'Cle' || this.configs.title === 'LinenRaj') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if (this.configs.title === 'Beaubelle') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if(this.configs.title === "BBold"){
    this.router.navigate([`/product`], {queryParams: { terms:[category.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
  } 
  if(this.configs.title === "Mayan"){
    this.router.navigate([`/product`], {queryParams: { terms:[category.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
  } 
  else if(this.configs.title !== 'Aiema' && this.configs.title !== 'Cle' && this.configs.title !== 'LinenRaj' && this.configs.title !== 'Beaubelle' && this.configs.title !== 'BombayHardware' && this.configs.title !== 'AvonSeals' && this.configs.title !== 'SpecialWires' && this.configs.title !== 'Vistas' && this.configs.title !== 'BBold' && this.configs.title !== 'Deera' && this.configs.title !== 'BestGifts'&& this.configs.title !== 'Carience'&& this.configs.title !== 'Vimpro'&&this.configs.title!=='Karupattiraja'){
    //console.log("buG")
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }
  this.searchForm.controls['search_term'].setValue('');
		//this.router.navigate([`/productListing`], { queryParams: { terms: [category.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }
  

  navigateToList1(category) {
    this.searchForm.controls['search_term'].setValue(category);

  if (this.configs.title === 'BombayHardware' || this.configs.title === 'AvonSeals' || this.configs.title === 'SpecialWires' || this.configs.title === 'Vistas') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  } 
  if (this.configs.title === 'Aiema' || this.configs.title === 'Cle' || this.configs.title === 'LinenRaj') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  } 
  if (this.configs.title === 'Beaubelle') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }

	}

  routeTo(){
    document.getElementById("mySidenav").style.width = "0";
  }

  routeTo1(){
    document.getElementById("closebeforecheck").click()
    this.router.navigate(['/checkout-new'])
  }

  routeTo2(){
    document.getElementById("closebeforecheck").click()
    this.router.navigate(['/cart'])
  }
 

  productsList(){
    if(this.configs.title === 'AvonSeals' || this.configs.title === 'SpecialWires' || this.configs.title === 'Vistas' || this.configs.title === 'Cle' || this.configs.title === 'LinenRaj'){
      document.getElementById("mySidenav").style.width = "0";
    }
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }

  productsLists(){
    if(this.configs.title === 'AvonSeals' || this.configs.title === 'SpecialWires' || this.configs.title === 'Vistas'){
      document.getElementById("mySidenav").style.width = "0";
    }
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }

  productsListkubendran(category){
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  
  }
  productsListkubendran1(category){
  if(category.includes('others'))
  {
    document.getElementById(category).style.width = "100%";
  }
  else{
    document.getElementById(category).style.width = "50%";
  }
  document.getElementById("mySidenav").style.width = "0";
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
  }
  productsListkubendran2(category,categoryname){
    if(category.includes('others'))
    {
      document.getElementById(category).style.width = "100%";
    }
    else{
      document.getElementById(category).style.width = "50%";
    }
    document.getElementById("mySidenav1").style.width = "0";
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[categoryname],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      
    }

  closePrelist(){
    this.searchForm.controls['search_term'].setValue('');
    this.preList = [];
  }     
  cartpopUp(){
    console.log("cartpopUp")
    document.getElementById('cartbutton_ln').click()
  }

  calculateTotal()
  {

    if(this.appService.cartProducts.results.length > 0) {
      if(this.configs.title!=='Deera'){
        this.totalPrice = 0;
        console.log("totalPrice")
        this.appService.cartProducts.results.forEach((obj)=>{
          console.log("obj.quantity"+obj.quantity)
          this.totalPrice += (obj.cost * obj.quantity);
        })
  
      } if(this.configs.title==='Deera'){
        {
          this.totalPrice = 0;
          let i: number = 0;
          this.appService.cartProducts.results.forEach((obj)=>
          {
              // this.totalPrice += (obj.product.discount_value).toFixed(2);
              console.log(obj)
              this.totalPrice += (obj.product.discount_value);
              console.log("TOTAL PRICE: ", this.totalPrice);
              i++
          })
          console.log("TOTAL ITEMS: ", i)
        }
  
      }
    }
    else
    {
      this.totalPrice = 0;
    }
  }

  loadExistingCart()
  {
    this.existingCartProduts= [];
    this.appService.cartProducts.results.forEach(value=>
      {
        this.existingCartProduts.push(value.product_id);
      })
  }

}
