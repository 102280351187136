import { environment } from 'src/environments/environment';
import { config } from 'src/configs/config'

export const API_URL = environment.apiURL;


export const ENDPOINTS = {

    LOGIN : API_URL + ":5202/login ",
    OTP_LOGIN: API_URL + ":5202/otplogin",
    REGISTRATION: API_URL + ":5447/customer/create",
    CUSTOMER_CREATE : API_URL + ':5447/customer/create',
    FORGOT_PASSWORD : API_URL + ':8051/forgotpassword',
    GENERATE_PASSWORD: API_URL+':5447/customer/generatepassword',
    VERIFY_PASSWORD : API_URL + ':5447/customer/verifypassword',
    CHANGE_PASSWORD : API_URL + ':5447/customer/updatepassword',
    RESET_PASSWORD : API_URL + ':5447/customer/resetpassword',
    OUTLET : API_URL + ':9514/outlet/getall',
    GET_ALLCATEGORIES: API_URL + ':9443/category/getall',
    GET_ALL_PRODUCTS: API_URL+':9444/product/getall',
   GET_FILTER_PRODUCTS: API_URL + ':9448/product/filterproducts',
    //GET_FILTER_PRODUCTS: API_URL + ':9448/product/randomfilterproducts',
    GIFT_MEAL_CREATE : API_URL + ':5467/giftmeal/create',
    CART_KAFKA_UPSERT : API_URL + ':5512/cart/create',
    STORE_SETTING_GET_ALL : API_URL + ":9520/storesettings/getall",
    GETALL_USERS : API_URL + ':9526/customer/getall',
    GET_CUSTOMER_ADDRESSES : API_URL + ':9455/CustomerAddress/getall',
    CREATE_CUSTOMER_ADDRESSES : API_URL + ':5514/customeraddress/create',
    UPDATE_CUSTOMER_ADDRESSES : API_URL + ':5514/customeraddress/update',
    DELETE_CUSTOMER_ADDRESSES : API_URL + ':5514/customeraddress/delete',
    SUBSCRIBE: API_URL +':5513/customer/update',
    STORE_GALLERY: API_URL+":9518/storegallery/getall",
    GET_PRELIST: API_URL+':9447/product/globalsearch',
    GET_SEARCH_RRESULTS: API_URL+':9447/product/globalsearch',
    GET_SEARCH_RESULT: API_URL+':9447/product/searchresultspage',
    GET_ALLBRANDS: API_URL+':9419/brand/getall',
    UPDATE_ORDER: API_URL+':5456/order/update',
    CREATE_ORDER: API_URL+':5456/order/create',
    GETALL_ORDERS: API_URL+':9475/order/getall',
    GETBYID_ORDERS: API_URL+':9475/order/getbyid',
    RELATED_PRODUCTS: API_URL+':9446/product/getrelatedproducts',
    GET_CUSTOMER: API_URL + ':9526/customer/getbyid',
    GET_CUSTOMER_DETAILS: API_URL + ':9526/customer/logincustomerdetails',
    GET_ERXTRY_DETAILS: API_URL + ':6531/erxtry/getall',
    GETALL_CUSTOMER: API_URL + ':9526/customer/getall',
    GETALL_CUSTOMER_DETAILS: API_URL + ':9526/customer/checkmember',
    UPDATE_CUSTOMER: API_URL + ':5447/customer/updatecustomer',
    GET_CUSTOMER_ADDRESS: API_URL + ':9455/customeraddress/getall',
    ADD_TABLEBOOKING: API_URL + ':5466/tablebooking/create',
    CREATE_GIFT_MEAL : API_URL + ':5467/giftmeal/create',
    FAQS_GET_ALL : API_URL + ':9482/FAQ/getall',
    UPDATE_CUSTOMER_ADDRESS: API_URL + ':5514/customeraddress/update',
    ADD_CUSTOMER_ADDRESS: API_URL + ':5514/customeraddress/create',
    DELETE_CUSTOMER_ADDRESS: API_URL + ':5431/customeraddress/delete',
    GET_ALL_TERMS_AND_CONDITION: API_URL + ':9480/termsandconditions/getall',
    GET_ALL_SHIPPING_AND_DELIVERY: API_URL + ':9605/shippinganddelivery/getall',
    GET_ALL_PRIVACY_POLICY: API_URL + ':9479/privacypolicy/getall',
    GET_ALL_FAQ: API_URL + ':9482/FAQ/getall',
    GET_ALL_BLOG: API_URL + ':9483/blog/getall',
    GET_ALL_COUNTRIES: API_URL + ':5300/es-countries/all',
    GET_FEATURED_PRODUCTS: API_URL + ':9446/product/getfeaturedproducts',
    BLOGS_GET_ALL : API_URL + ':9483/blog/getall',
    SEND_OTP : API_URL + ':5622/sms/GenerateOtpSMS ',
    VERIFY_OTP : API_URL + ':5622/sms/verifyotp',
    GET_OUTLETS : API_URL + ':9514/outlet/getall',
    GET_SHIPMENTMETHODS : API_URL + ':9456/shippingmethods/getall',
    GET_TAXZONES : API_URL + ':9524/taxzone/getall',
    PICKANDDROP : API_URL + ':9467/orderdelivery/getrates',
    PAYMENTDATAINSERTION : API_URL + ':5621/api/payment/InsertPayment',
    TAXSETTINGS:  API_URL + ':9531/taxsettings/getall',
    ACCEPTANCE_TIMINGS: API_URL + ':9530/acceptancetimings/getall',
    GET_DISCOUNTS: API_URL + ':9527/promotions/getall',
    GET_PAYMENTMETHODS: API_URL + ':9521/paymentmethods/getall',
    GET_PAYMENTSETTINGS: API_URL + ':9533/ecomintegrationsettings/getall',
    GET_ORDERSTATUS: API_URL + ':9522/orderstatus/getall',
    GET_CURRENCIES: API_URL + ':9523/currencies/getall',
    ORDER_UPDATION: API_URL + ':5456/order/upsert',
    ORDER_STATUS_FLOW : API_URL + ':9528/orderstatusflow/getall',
    ICICPAYMENTHASH : API_URL + ':5621/api/payment/generateIPGHash',
    PROMOTIONS_BANNER: API_URL + ':9534/promotionsbanner/getall',
    QUANTITY_PROMOTION: API_URL + ':9911/promotions/getpromo',
    STRUCTURED_PROMOTION: API_URL + ':9911/promotions/getstructuredpromo',
    STRUCTURED_GROUP_PROMOTION: API_URL + ':9911/promotions/getstructuredgrouppromo',
    // UPLOAD_MASK_IMAGE: API_URL + ':9432/imageupload/create',
    UPLOAD_MASK_IMAGE: API_URL + ':9104/imageupload/create',
    WRITERS_GET_RATES:API_URL + ':5372/writers/getrates',
    GET_ALL_OFFERS_CUSTID: API_URL + ':9911/promotions/getalloffersbycustid',
    GET_ALL_OFFERS: API_URL + ':9911/promotions/getalloffers',
    // UPLOAD_MASK_IMAGE: 'http://164.52.214.114' + ':9432/imageupload/create',
    // UPLOAD_MASK_IMAGE: 'http://164.52.214.114' + ':9104/imageupload/create',
    Get_PRODUCT_ARRAY: API_URL + ':9444/product/getbymultipleids ',
    RELATEDPRODUCT_API: API_URL + ':9446/product/getrelatedproducts',
    GETORDERDETAILS: API_URL + ':9475/order/getbyid',
    GETALLCARTITEMS: API_URL + ':9516/cart/getall',
    GETSHIPPINGCARRIERS: API_URL + ':9536/customshippingcarriers/getall',
    STOREGALLERY: API_URL + ':9518/storegallery/getall',
    GETALL_GIFTMEALSETTINGS: API_URL + ':9543/giftamealsettings/getall',
    GETALL_TABLEBOOKINGSETTINGS: API_URL + ':9987/tablebookingsettings/getall',
    GETALL_TABLEBOOKINGTIMESLOT: API_URL + ':9987/tablebookingsettings/getdetails',
    GETALL_GIFTMEALTIMESLOT: API_URL + ':9543/giftamealsettings/getdetails',
    GETALL_TABLEBOOKINGAVAILABLESEAT: API_URL + ':9987/tablebookingsettings/getperson',
    GETALL_GIFTMEALAVAILABLESEAT: API_URL + ':9543/giftamealsettings/getperson',
    ADDREVIEW: API_URL + ':5518/productreviews/create',
    EDITREVIEW: API_URL + ':5518/productreviews/update',
    GETALLREVIEW: API_URL + ':9545/productreviews/getall',
    PRODUCT_DETAILS: API_URL + ':9444/product/getbyid',
    SEND_OTP_EMAIL : API_URL + ':5622/sms/GenerateOtpEmail',
    CHECKOUT_FLOW: API_URL + ':9549/checkoutprocessflow/getall',
    CHECKOUT_SETTINGS: API_URL + ':9555/checkoutsettings/getall',
    PAYU_LINK: API_URL + ':5621/api/payment/generatePayuLink',
    REGISTRATION_OFFER: API_URL + ':9559/promotions/getall',
    DELHIVERY_CHARGES: API_URL + ':9578/orderdelivery/invoiceCharge',
    COURIER_CHARGES: API_URL + ':7887/customshippingcarrier/getall',
    DUNZO_CHARGES: API_URL + ':9577/orderdelivery/quote',
    SERVICE_AVAILABILITY: API_URL + ':9578/orderdelivery/serviceAvailability',
    DELIVERY_AVAILABILITY:API_URL + ':9579/orderdelivery/courierServiceability',
    GET_CANCELLATIONPOLICY : API_URL + ":9602/cancellation/getall",
    GET_RETURNPOLICY : API_URL + ":9603/returnandexchange/getall",
    GET_CONTACT_US : API_URL + ":9610/contactus/getall",
    GET_ABOUT_US : API_URL + ":9481/aboutus/getall",
    DOWNLOAD_VIMPRO : API_URL + ":5520/api/Email/downloadvimprocompany",
    DOWNLOAD_VIMPRO_TIPS : API_URL + ":5520/api/Email/downloadvimprotips",
    CREATE_CONTACT_US : API_URL + ":5606/contacteduser/create",
    CONTACTUS_GETALL: API_URL + ":9610/contactus/getall",
    HDFC_PAYMENT : API_URL + ":5621/api/payment/generateHDFCLink",
    ORDER_ACTIVATION : API_URL + ":5621/api/payment/orderactivation",
    CREATE_WISHLIST: API_URL + ":5607/wishlist/create",
    UPDATE_WISHLIST: API_URL + ":5607/wishlist/update",
    DELETE_WISHLIST: API_URL + ":5607/wishlist/delete",
    GETALL_WISHLIST: API_URL + ":9612/wishlist/getall",
    GETBYID_WISHLIST: API_URL + ":9612/wishlist/getbyid",
    GETSHIPPING_SETTINGS: API_URL + ":9990/shippingsettings/getall",
     GET_LOCATION_DISTANCE: API_URL + ":9658/google/distance",
    //GET_LOCATION_DISTANCE: API_URL + ":9900/google/distance",
    ADD_ENQUIRY: API_URL + ':7402/enquiry/create',
    ADD_MEMBER: API_URL + ':7403/member/create',
    GETALL_COUPONCODES: API_URL + ':9427/couponcode/getdiscountamount',
    // GETOFFER_BYCUSTID: API_URL + ':9911/promotions/getalloffersbycustid',
    
    GETALLOFFER: API_URL + ':9911/promotions/getalloffers',
    SENDVIMPRO_QUERY: API_URL + ':5520/api/Email/sendvimproquery',
    INVESTOR_CREATE: API_URL + ':3304/investor/create',
    GET_SELECTIONPROCESS: API_URL + ':6538/selectionprocess/getall',

}

export const ORG_DETAILS = {
    DOMAIN_NAME : config.DOMAIN_NAME,
    USER_ID : config.USER_ID,
    STORE_ID: config.STORE_ID,
    ORGANIZATION_ID: config.ORGANIZATION_ID,
    NAME: config.name
}
