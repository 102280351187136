

<!-- <app-header></app-header>
<main>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer> -->
<app-header  [item]='navURL' *ngIf="runApp===true && (config.title == 'Tams' || config.title == 'Dosapark' || config.title == 'Murgasen' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-header>
<!-- <app-header  [item]='navURL' *ngIf="config.title == 'Murgasen'"></app-header> -->
<app-header  [item]='navURL' *ngIf="runApp===true && (config.title == 'Haven' || config.title == 'Nesma' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-header>
<app-two-header *ngIf="runApp===true && (config.title == 'EarthyWorthy'  && currentUrl !== 'checkout' && currentUrl !== 'knowily') "></app-two-header>
<app-two-header *ngIf="runApp===true &&(config.title == 'BBold'  && currentUrl !== 'checkout' && currentUrl !== 'knowily')"></app-two-header>
<app-two-header *ngIf="runApp===true &&(config.title == 'Karupattiraja'|| config.title == 'Accel' || config.title == 'LinenRaj'||config.title == 'LuckMe'|| config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts' || config.title == 'SpecialWires'  || config.title == 'Vistas' || config.title == 'Kubendran' || config.title == 'Aiema' || config.title == 'Cle' || config.title == 'Beaubelle' || config.title == 'Mayan'|| config.title == 'BombayHardware' || config.title == 'AvonSeals' || config.title == 'Carience' || config.title == 'Vimpro') && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-two-header>
<main *ngIf="runApp===true">
    <router-outlet></router-outlet>
    <button *ngIf="(config.title !== 'AvonSeals' && config.title !== 'Aiema' && config.title !== 'Cle' && config.title !== 'LinenRaj' && config.title !== 'Vimpro' && config.title !== 'SpecialWires' && config.title !== 'Vistas')" (click)="topFunction()" id="myBtn" title="Go to top" data-toggle="tooltip" data-placement="left"><i class="fa fa-angle-up"></i></button>
    <button *ngIf="config.title === 'Vimpro' || config.title === 'SpecialWires'  || config.title === 'Vistas' || config.title == 'AvonSeals' || config.title == 'Cle' || config.title == 'LinenRaj'" (click)="topFunction()" id="myBtn" title="Go to top" data-toggle="tooltip" data-placement="left"><i class="fa fa-angle-up buttonupfun"></i></button>
    <!-- <button *ngIf="config.title == 'AvonSeals'" style="padding: 12px !important;" (click)="topFunction()" id="myBtn" title="Go to top" data-toggle="tooltip" data-placement="left"><i class="fa fa-angle-up"></i></button> -->
    <button *ngIf="config.title == 'Aiema'" style="background-color: #000!important;padding: 12px 18px;" (click)="topFunction()" id="myBtn" title="Go to top" data-toggle="tooltip" data-placement="left"><i class="fa fa-angle-up"></i></button>
</main>
<app-footer *ngIf="runApp===true &&(config.title == 'Tams' || config.title == 'Murgasen' || config.title == 'Mayan' || config.title == 'Accel' )  && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-footer>
<app-footer *ngIf="runApp===true &&(config.title == 'Haven' || config.title == 'Dosapark' || config.title == 'Nesma' ) && currentUrl !== 'checkout' && currentUrl !== 'knowily'"></app-footer>
<app-two-footer *ngIf="runApp===true &&(config.title == 'EarthyWorthy' || config.title == 'BBold' || config.title == 'BombayHardware' || config.title == 'AvonSeals' || config.title == 'SpecialWires' || config.title == 'Accel' || config.title === 'Vistas'|| config.title === 'LinenRaj'   || config.title == 'Aiema' || config.title == 'Cle' || config.title == 'Beaubelle' || config.title == 'Vimpro'  && currentUrl !== 'checkout' && currentUrl !== 'knowily')"><h1>bbold header</h1></app-two-footer>
<app-loader *ngIf="runApp===true"></app-loader>
<app-luckme-footer *ngIf="runApp===true &&(config.title == 'Karupattiraja'||config.title == 'LuckMe' || config.title == 'ShubhCards' || config.title == 'Deera' || config.title == 'BestGifts'|| config.title == 'Kubendran' || config.title == 'Carience')"></app-luckme-footer>
